<template>
    <div class="flex flex-col mt-8">
        <PaginationNav v-if="data.length > limit" @flip="setPage"/>
        <div class="my-4">
            <component :is="element" v-if="paginated.length" :class="classes">
                <slot name="card" :items="paginated"></slot>
            </component>
            <p v-else class="py-4 px-5 sm:px-6">
                <slot name="none">There are currently no items.</slot>
            </p>
        </div>
        <PaginationNav v-if="data.length > limit" @flip="setPage"/>
    </div>
</template>

<script>
import { computed } from 'vue';
import PaginationNav from './PaginationNav.vue';

export default {
    data() {
        return {
            page: 1
        }
    },
    methods: {
        setPage(direction) {
            if (direction == 'next') {
                this.page++;
            } else {
                this.page--;
            }
        }
    },
    computed: {
        paginated() {
            return this.data.slice(this.from, this.to);
        },
        from() {
            return (this.page * this.limit) - this.limit;
        },
        to() {
            let to = this.page * this.limit;
            return to <= this.data.length ? to : this.data.length;
        },
        classes() {
            let required = "grid gap-4".split(' ');
            let base = (this.$attrs.class ?? "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6").split(' ');
            return [...new Set([...required, ...base])].join(' ');
        }
    },
    props: {
        data: {
            type: Array,
            required: true,
            default: () => []
        },
        limit: {
            type: Number,
            default: 24
        },
        element: {
            type: String,
            default: 'ul'
        }
    },
    provide() {
        return {
            limit: this.limit,
            from: computed(() => this.from),
            to: computed(() => this.to),
            count: computed(() => this.data.length),
            page: computed(() => this.page)
        }
    },
    components: {
        PaginationNav
    }
}
</script>