<template>
    <LoadingView v-if="isLoading" />
    <div v-else class="bg-white shadow sm:rounded">

        <nav 
            class="flex flex-none gap-4 justify-between items-center order-1 lg:order-2 rounded-t-lg" 
            :class="{
                'bg-blue-200' : floorplan.status == 'new', 
                'bg-green-200' : floorplan.status == 'active', 
                'bg-oakwood-gray-200' : floorplan.status == 'inactive'
            }">
            <div class="px-5 flex-none">
                <FormButton 
                    class="shadow-none bg-white opacity-80 hover:opacity-100 transition-opacity delay-75" 
                    :class="{
                        'bg-blue-300': floorplan.status == 'new',
                        'bg-green-300': floorplan.status == 'active',
                        'bg-oakwood-gray-300': floorplan.status == 'inactive'
                    }"
                    icon="fa-chevron-left" :to="{ name: 'floorplan-list' }">
                    Return to List
                </FormButton>
            </div>
            <div class="py-4 px-5 sm:rounded-tr-lg flex justify-end">
                <ToggleButton 
                    id="publish-toggle" 
                    :colors="{
                        on: 'bg-green',
                        off: floorplan.status == 'new' ? 'bg-blue' : 'bg-oakwood-gray-800',
                        text_on: 'text-green',
                        text_off: floorplan.status == 'new' ? 'text-blue' : 'text-oakwood-gray-800'
                    }"
                    :checked="floorplan.status == 'active'" 
                    @changed="changeStatus" label="Set Publishing Status" />
                <label for="publish-toggle"
                    :class="{
                        'bg-blue' : floorplan.status == 'new', 
                        'bg-green' : floorplan.status == 'active', 
                        'bg-oakwood-gray-800' : floorplan.status == 'inactive'
                    }"
                    class="text-white px-2 inline-block text-xs leading-6 font-semibold rounded-full ml-2"
                    v-text="statusLabel"></label>
            </div>
        </nav>
        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-start gap-8">
            <div class="flex flex-col lg:flex-row gap-5 sm:gap-6 flex-1">
                <div class="flex flex-col gap-5 sm:gap-6 flex-1 justify-between">
                    <div class="flex flex-col lg:flex-row gap-5 justify-between flex-none">

                        <div class="flex-1 flex flex-col gap-2 lg:gap-0 order-2 lg:order-1">
                            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                                {{ floorplan.meta.info?.name || floorplan.kova.MarketingName }}
                                    <span v-if="!['N/S',''].includes(floorplan?.kova.CustomFields.TextCommModelStatus1)" class="bg-oakwood-gray text-white px-2 rounded-full inline-block text-xs leading-5 relative bottom-1 ml-2">{{ floorplan?.meta?.info?.callout }}</span>
                            </h1>
                            <dl class="lg:flex gap-2">
                                <dt class="sr-only">
                                    Collection
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    <router-link :to="{name: 'collection-show', params: {id : floorplan.collection.id}}" class="text-oakwood-blue-600">
                                    {{ floorplan.collection.kova?.MarketingName }}
                                    </router-link>
                                </dd>
                                <dt class="mt-1 text-sm leading-5 text-oakwood-gray-900">
                                    Kova Model ID:
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    {{ floorplan.kova?.ModelRID }}
                                </dd>
                                <dt class="sr-only">
                                    Name
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900">
                                    {{ floorplan.kova?.Name }}
                                </dd>
                                
                            </dl>
                        </div>
                    </div>
                    <section class="flex flex-row flex-wrap border border-oakwood-gray-200 rounded">
                        <h2 class="sr-only">Basic Information</h2>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center"
                                :class="[!['Pre-Sale (VIP)', 'Off Market', 'Sold Out', 'Internal', '', 'N/S'].includes(kovaStatus) ? 'bg-oakwood-green-700' : 'bg-oakwood-red-500']">
                                <span class="far"
                                    :class="[!['Pre-Sale (VIP)', 'Off Market', 'Sold Out', 'Internal', '', 'N/S'].includes(kovaStatus) ? 'fa-lightbulb-on' : 'fa-lightbulb-slash']"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Sales Status</dt>
                                <dd class="font-semibold">{{ kovaStatus }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-house"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Type</dt>
                                <dd class="font-semibold">{{ floorplan.kova?.Type }}</dd>
                            </dl>
                        </aside>


                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-circle-dollar"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Price</dt>
                                <dd class="font-semibold">{{ priceRange }}</dd>
                            </dl>
                        </aside>


                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-arrows-maximize"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Square Footage</dt>
                                <dd class="font-semibold">{{ sqftRange }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-bed"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Bedrooms</dt>
                                <dd class="font-semibold">{{ bedRange }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-shower"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Full Bathrooms</dt>
                                <dd class="font-semibold">{{ fullBathRange }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-toilet-paper-blank"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Half Bathrooms</dt>
                                <dd class="font-semibold">{{ halfBathRange }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start w-1/2 lg:w-1/4">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-warehouse"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Garages</dt>
                                <dd class="font-semibold">{{ garageRange }}</dd>
                            </dl>
                        </aside>
                    </section>

                </div>
                <div class="w-full lg:w-1/5 flex">
                    <img v-if="floorplan.image" class="object-cover"
                        :src="floorplan.image" :alt="floorplan.kova?.Name"
                        :title="floorplan.kova?.Name">
                    <div v-else class="text-5xl bg-oakwood-blue-300 text-white p-8 flex items-center justify-center w-full">
                        <span class="far fa-house"></span>
                    </div>
                </div>

            </div>
        </header>
        <TabPanes route="floorplan-tab" :tabs="tabs" v-slot="{tab}">
            <InfoPane :floorplan="floorplan" @update="prepare" v-if="tab?.name === 'info'" />
            <ImagePane :parent="floorplan" context="floorplan" @update="(image) => {floorplan.image = image.media?.url}" v-if="tab?.name === 'photos'" />  
            <VideoPane :item="floorplan" context="floorplan" @update="prepare" v-if="tab?.name === 'videos'" />
            <InteractivePane :item="floorplan" context="floorplan" @update="prepare" v-if="tab?.name === 'interactive'" />
            <LogPane context="floorplan" v-if="tab?.name === 'logs'" />

                
            <template v-if="tab?.name === 'debugger'">
                <h2>Raw Data</h2>
                <pre>
                    {{ floorplan }}
                </pre>
            </template>
        </TabPanes>
    </div>


</template>

<script>
import axios from "axios";
import LoadingView from '@/components/LoadingView.vue';
import {FormButton, ToggleButton} from '@/components/form';
import {InfoPane} from '@/components/floorplans';
import {InteractivePane, ImagePane, VideoPane, LogPane} from '@/components/shared';
import { mapState } from "pinia";
import { useAuthStore } from "@/stores/auth";
import { floorplan, handle404 } from "@/helpers/globals";
import TabPanes from "@/components/TabPanes.vue";
import Floorplan, { helpers } from "@/models/Floorplan";

let tabs = [
    {
        name: 'info',
        icon: 'fa-circle-info',
        title: 'General Information'
    },
    {
        name: 'photos',
        icon: 'fa-images',
        title: 'Images'
    },
    {
        name: 'videos',
        icon: 'fa-video',
        title: 'Videos'
    },
    {
        name: 'interactive',
        icon: 'fa-panorama',
        title: 'Interactives'
    },
    {
        name: 'logs',
        icon: 'fa-timer',
        title: 'History'
    },
    {
        name: 'debugger',
        icon: 'fa-bug-slash',
        title: 'Debugger'
    },
];

export default {
    head() {
        return {
            title: () => `${this.$route.meta.title} %separator ${this.floorplan?.meta?.info?.name || this.floorplan?.kova?.MarketingName}`
        }
    },
    data() {
        return {
            floorplan: new Floorplan({id: this.$route.params.id}),
            isLoading: true,
            tabs: []
        }
    },
    methods: {
        show() {
            axios.get(`floorplans/${this.floorplan.id}`)
                .then(res => {
                    this.floorplan = new Floorplan(res.data);
                    this.isLoading = false;
                })
                .catch(err => {
                    if(err.response.status === 404) 
                        handle404(this, err.response.data.message);
                });
        },
        changeStatus(toggle) {
            this.floorplan.status = toggle ? 'active' : 'inactive';
            this.save();
        },
        save() {
            this.isLoading = true;
            axios.patch(`floorplans/${this.floorplan.id}`, this.floorplan)
                .then(res => {
                    this.isLoading = false;
                })
                .catch(e => {
                    throw e;
                });
        },
        prepare(value) {
            this.floorplan = new Floorplan(value);
            this.save();
        }
    },
    computed: {
        ...mapState(useAuthStore, ['user']),
        kovaStatus() {
            return floorplan.ModelStatus[this.floorplan.kova.CustomFields.TextCommModelStatus1];
        },
        lot() {
            let lotId = this.floorplan.kova?.Lot?.LotID;
            if(!lotId) return '-';
            return parseInt(lotId.split('-').pop());
        },
        statusLabel() {
            switch(this.floorplan.status) {
                case 'active' :
                    return 'Published';
                case 'inactive' :
                    return 'Unpublished';
                case 'new' : 
                default :
                    return 'New';
            }
        },
        ...helpers
    },
    created() {
        this.show();
        this.tabs = tabs.filter(tab => {
            if(this.user.level < 2 && (tab.name == 'debugger' || tab.name == 'logs')) return false;
            return true;
        });
    },
    components: {
        LoadingView,
        ToggleButton,
        FormButton,
        TabPanes,
        InteractivePane,
        VideoPane,
        ImagePane,
        InfoPane,
        LogPane,
    },
}
</script>@/components/form@/components/floorplans@/components/shared