<template>
    <div>
        <div class="flex flex-col lg:flex-row justify-between items-center">
            <h2 class="text-xl font-medium text-oakwood-blue-600">Logs</h2>
        </div>
        <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4 mt-4 -mb-4">
            <p v-if="log_users.length" class="grid gap-2">
                <SelectField label="User" v-model="search.user">
                    <option :value="false">All Users</option>
                    <option v-for="(user, i) in log_users" :value="user?.id" :key="`user-${i}`">{{ `${user?.first_name}
                        ${user?.last_name}` }}</option>
                </SelectField>
            </p>
        </div>
        <div class="relative min-h-48">
            <LoadingView v-if="loading" :absolute="true" class="bg-opacity-0" />
            <PaginationGrid v-else :data="filtered" element="div" class="md:grid-cols-1 lg:grid-cols-1 gap-4">

                <template #card="{ items }">
                    <template v-for="log, i in items" :key="`log-${i}`">
                        <h3 v-if="i === 0 || log.created_at.date !== items[i - 1]?.created_at?.date"
                            class="text-xl font-semibold mt-4 text-oakwood-gray-900">{{ log.created_at.date }}</h3>
                        <div class="border-oakwood-gray-50 border p-6 rounded grid grid-cols-6 gap-4">
                            <div class="flex gap-4">
                                <span
                                    class="inline-block h-9 w-9 rounded-full text-center fa fa-user bg-oakwood-blue-500 text-white p-2"></span>
                                <div>
                                    <a :href="`mailto:${log.user?.email}`" v-if="log.user" class="text-lg font-medium">{{
                                        `${log.user?.first_name} ${log.user?.last_name}` }}</a>
                                    <p v-else class="text-lg font-medium"><em>System Process</em></p>
                                    <p class="text-sm text-oakwood-gray-700"
                                        :title="`${log.created_at.date} ${log.created_at.time}`">{{ log.created_at.time }}
                                    </p>
                                </div>
                            </div>
                            <ul class="flex flex-col gap-4 col-span-5">
                                <li v-for="entry,k in log.entries" :key="`entry-${k}`" class="grid grid-cols-8" :class="{'border-t border-t-oakwood-gray-50 pt-4' : k>0}">
                                    <p class="text-sm pt-[.3rem]">
                                        <span class="bg-green-300 text-sm px-2 inline-block rounded-full text-black">{{
                                                entry.action }}
                                            </span>
                                            #{{ entry.id }}
                                        </p>
                                    <div class="col-span-7 pt-[.1rem]">
                                        <p class="font-medium mb-2 text-oakwood-blue-600">
                                             
                                            {{ ucFirst(entry.model_type.replaceAll('_', ' ')) }}
                                            <span v-if="entry.model_type === 'attachment' && entry.model">{{ entry.model?.media?.alt }}</span>
                                        </p>
                                        <ul class="flex flex-col gap-2">
                                            <li v-for="difference,d in displayProse(entry.differences)" :key="`difference-${d}`"
                                                class="text-sm leading-loose"
                                                v-html="difference"></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </template>
                </template>

            </PaginationGrid>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import PaginationGrid from "../PaginationGrid.vue";
import SelectField from "../form/SelectField.vue";
import LoadingView from "../LoadingView.vue";

export default {
    data() {
        return {
            id: this.$route.params.id,
            logs: [],
            currentPage: 1,
            itemsPerPage: 5,
            loading: true,
            search: {
                user: false
            }
        };
    },
    computed: {
        filtered() {
            let results = this.logs;

            if (this.search.user !== false) {
                results = results.filter(log => log.user_id === this.search.user)
            }

            return results;
        },
        log_users() {
            if (!this.logs.length) return [];
            let users = [...new Set(
                this.logs.map(log => JSON.stringify({
                    id: log.user_id,
                    first_name: log.user?.first_name || 'System',
                    last_name: log.user?.last_name || 'Process'
                }))
            )].map(user => JSON.parse(user));
            return users;
        }
    },
    methods: {
        ucFirst(value) {
            return `${value[0].toUpperCase()}${value.slice(1)}`; 
        },
        index() {
            axios.get(`logs/${this.context}/${this.id}`)
                .then((res) => {
                    this.logs = res.data.map((log) => {
                        log.created_at = {
                            date: this.formatDate(log.created_at),
                            time: this.formatTime(log.created_at),
                        };
                        return log;
                    });
                    this.loading = false;
                })
                .catch((err) => { 
                    console.error(err); 
                    this.loading = false; 
                });
        },
        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }).format(new Date(date));
        },
        formatTime(date) {
            return new Intl.DateTimeFormat('en-US', {
                hour12: true,
                hour: 'numeric',
                minute: '2-digit',
            }).format(new Date(date));
        },
        displayKey(key) {
            return key.replaceAll(':', ' <span class="text-oakwood-blue-200 fas fa-chevron-right"></span> ');
        },
        displayProse(differences, and) {
            and = and || false;
            differences = Object.entries(differences);
            if (!differences.length) return [];
            let response = differences.map(([key, value], index) => {
                let fields = key.split(':'),
                    field = fields.reverse()[0],
                    response = `<span class="bg-oakwood-gray-50 px-1 py-[.15em] rounded-sm font-medium">${key.replaceAll(':', ' ')}</span> was set to <span class="bg-oakwood-blue-50 bg-opacity-50 px-1 py-[.15em] rounded-sm font-medium">${value || 'null'}</span>`;
                if (index !== 0 && index == differences.length - 1 && and) response = `${and} ${response}`;
                return response;
            });
            if (response.length) return response;
        }
    },
    props: {
        context: {
            type: String,
            required: true,
        },
    },
    components: {
        PaginationGrid,
        SelectField,
        LoadingView
    },
    created() {
        this.index();
    },
};
</script>
