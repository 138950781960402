const formatToCurrency = (value) => {
    if (typeof value !== 'number') {
      return value;
    }
  
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
      });
  
    return formatter.format(value);
};

const formatToNumber = (value) => {
  if (typeof value !== 'number') {
    return value;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  return formatter.format(value);
};

const numberFromBytes = (value) => {
  let suffix = 'B';
  if(value < 1000000) {
    value = Math.round((value/1000)*100)/100;
    suffix = 'KB';
  } else if(value >= 1000000 && value < 1000000000) {
    value = Math.round((value/1000000)*100)/100;
    suffix = 'MB';
  } else {
    value = Math.round((value/1000000000)*100)/100;
    suffix = 'GB';
  }
  return `${Intl.NumberFormat('en-us').format(value)} ${suffix}`;
}
  
const getRange = (min,max,currency) => {
  currency = currency || false;
  let retMin = currency ? formatToCurrency(min) : formatToNumber(min);
  let retMax = formatToNumber(max);
  return min === max ? retMin : `${retMin} – ${retMax}`;
};

export { formatToCurrency, formatToNumber, getRange, numberFromBytes }