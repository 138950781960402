<template>
    <label v-if="!inputAttrs?.id" :for="getId" :class="{'sr-only' : !showLabel}">{{ label }}</label>
    <div class="border border-oakwood-gray-200 rounded inline-flex items-stretch overflow-hidden" :class="$attrs['class']">
        <span aria-hidden="true" v-if="prefix" class="text-oakwood-gray-400 p-2 border-r border-r-oakwood-gray-100 rounded-l">{{ prefix }}</span>
        <input 
            :ref="`input-${this.$.uid}`"
            :id="getId" 
            class="flex-0 p-2 number-field focus:border-oakwood-blue-400" 
            :class="width"
            type="number" 
            :value="value"
            @input="update"
            v-bind="inputAttrs"
        />
        <span aria-hidden="true" v-if="suffix" class="text-oakwood-gray-400 p-2">{{ suffix }}</span>
        <span v-if="arrows" aria-hidden="true" class="flex flex-col border-l border-l-oakwood-gray-100">
            <button type="button" @click="increment('up')" class="text-xs flex-1 text-oakwood-gray-900 hover:bg-oakwood-gray-50 pt-[.125rem] px-2 rounded-tr-lg">
                <span class="far fa-chevron-up"></span>
            </button>
            <button type="button" @click="increment('down')" class="text-xs flex-1 text-oakwood-gray-900 hover:bg-oakwood-gray-50 pb-[.125rem] px-2 rounded-br-lg">
                <span class="far fa-chevron-down"></span>
            </button>
        </span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            debounce: null
        }
    },
    emits: [
        'update:modelValue'
    ],
    props: {
        prefix: {
            type: [Boolean, String],
            default: false
        },
        suffix: {
            type: [Boolean, String],
            default: false
        },
        modelValue: {
            required: true
        },
        width: {
            type: String,
            default: "w-20"
        },
        arrows: {
            type: Boolean,
            default: true
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        getId() {
            return this.$attrs.id || `field-${this.$.uid}`;
        },
        inputAttrs() {
            let $attrs = {
                ...this.$attrs,
                min: this.$attrs.min || 0,
                step: this.$attrs.step || 1
            };
            delete $attrs.class
            return $attrs
        }
    },
    methods: {
        increment(direction) {
            direction == 'up' ? this.$refs[`input-${this.$.uid}`].stepUp() : this.$refs[`input-${this.$.uid}`].stepDown();
            this.$refs[`input-${this.$.uid}`].dispatchEvent(new Event('input'));
        },
        update(e) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                let y = e.target.value.replace(/[^0-9\.-]/g, '').match(/-?[0-9]*\.?[0-9]*/);
                e.target.value = y[0];
                if(!e.target.checkValidity()) {
                    e.target.value = this.value;
                } else {
                    this.value = e.target.value;
                }
            }, 500)
        }
    }   
}
</script>

<style>
.number-field {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
.number-field::-webkit-inner-spin-button, 
.number-field::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}
</style>