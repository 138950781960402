<template>
    <div class="flex flex-col mt-8">
        <PaginationNav v-if="data.length > limit" @flip="setPage"/>

        <div class="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-oakwood-gray-200 sm:rounded">
                    <table v-if="paginated.length" class="min-w-full divide-y divide-oakwood-gray-200">
                        <thead>
                            <tr class="bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider hidden sm:table-row">
                                <slot name="headers"></slot>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-oakwood-gray-200">
                            <tr v-for="item, i in paginated" :key="`item-${i}`"
                                class="hover:bg-oakwood-blue-50 hover:bg-opacity-30">
                                <slot name="columns" :row="item" :i="i"></slot>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else class="py-4 px-5 sm:px-6">
                        There are currently no items.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <PaginationNav v-if="data.length > limit" @flip="setPage"/>
</template>

<script>
import { computed } from 'vue';
import PaginationNav from './PaginationNav.vue';

export default {
    data() {
        return {
            page: 1
        }
    },
    methods: {
        setPage(direction) {
            if (direction == 'next') {
                this.page++;
            } else {
                this.page--;
            }
        }
    },
    computed: {
        paginated() {
            return this.data.slice(this.from, this.to);
        },
        from() {
            return (this.page * this.limit) - this.limit;
        },
        to() {
            let to = this.page * this.limit;
            return to <= this.data.length ? to : this.data.length;
        },
        max() {
            return Math.ceil(this.data.length / this.limit);
        },
    },
    props: {
        data: {
            type: Array,
            required: true,
            default: () => []
        },
        limit: {
            type: Number,
            default: 20
        }
    },
    provide() {
        return {
            limit: this.limit,
            from: computed(() => this.from),
            to: computed(() => this.to),
            count: computed(() => this.data.length),
            page: computed(() => this.page)
        }
    },
    watch: {
        data: {
            handler() {
                this.page = 1;
            },
            deep: true
        }
    },
    components: {
        PaginationNav
    }
}
</script>